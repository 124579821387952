import { getDataFromResponse, getUnAuthenticatedAxios } from './helpers';

const CatalogsApiEndpoints = {
  getCatalogueDetails: (id) => getUnAuthenticatedAxios()
    .get(`/catalog/${id}`)
    .then(getDataFromResponse),

  searchByCatalogue: (name) => getUnAuthenticatedAxios()
    .get('/catalog', { params: { name } })
    .then(getDataFromResponse),  
};

export default CatalogsApiEndpoints;
