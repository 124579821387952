import React, { Component } from "react";
import { Trans } from "react-i18next";
import { ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";
import Map from "../../components/shared/GoogleMap";
import "react-toastify/dist/ReactToastify.css";

export class ContactUs extends Component {
  state = {
    data: {
      firstName: "",
      lastName: "",
      email: "",
      message: "",
      phoneNumber: "",
      time: Date.now()
    }
  };

  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Contact Us</title>
        </Helmet>
        <ToastContainer />
        <section className="contact-us">
          <div className="title">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="text-white mb-0">
                    <Trans i18nKey="contact_us"></Trans>
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="box">
            <div className="container">
              <div className="row no-gutters">
                <div className="col-md-3">
                  <div className="info-box">
                    <ul className="list-unstyled p-0 m-0">
                      <li className="text-white small mb-1">
                        <Trans i18nKey="tel"></Trans>: <span dir="ltr">+96264651701</span>
                      </li>
                      <li className="text-white small">
                        <Trans i18nKey="fax"></Trans>: <span dir="ltr">+96264652701</span>
                      </li>
                      <li className="text-white small">
                        <Trans i18nKey="mobile"></Trans>: <span dir="ltr">+962777600701</span>
                      </li>
                    </ul>
                    <hr />
                    <ul className="list-unstyled p-0 m-0">
                      <li className="text-white small mb-1">
                        <Trans i18nKey="email"></Trans>: info@qudsico.com
                      </li>
                      <li className="text-white small">
                        <Trans i18nKey="address"></Trans>:{" "}
                        <Trans i18nKey="wadi_saqra"></Trans>
                      </li>
                      <li className="text-white small mb-1">
                        <Trans i18nKey="po_box"></Trans>: 164{" "}
                        <Trans i18nKey="amman"></Trans> 11953{" "}
                        <Trans i18nKey="jordan"></Trans>
                      </li>
                    </ul>
                    <hr />
                    <ul className="list-unstyled p-0 m-0">
                      <li className="text-white small d-flex flex-row align-items-center">
                        <Trans i18nKey="social_media"></Trans>:
                        <ul className="list-unstyled p-0">
                          <li
                            className="list-inline-item"
                            onClick={() =>
                              window.open(
                                "https://www.facebook.com/QudsiFurniture/",
                                "_blank"
                              )
                            }
                          >
                            <img
                              src="/assets/images/facebook.png"
                              alt="Facebook"
                              width="8"
                              className="clickable mx-1"
                            />
                          </li>
                          <li
                            className="list-inline-item"
                            onClick={() =>
                              window.open(
                                "https://www.instagram.com/qudsi_office_furniture/",
                                "_blank"
                              )
                            }
                          >
                            <img
                              src="/assets/images/instagram.png"
                              alt="Instagram"
                              width="15"
                              className="clickable mx-1"
                            />
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="contact-box shadow">
                    <form>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              <Trans i18nKey="first_name"></Trans>
                            </label>
                            <input
                              className="form-control"
                              onChange={this.handleChange}
                              value={this.state.data.firstName}
                              name="firstName"
                              required
                            ></input>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              <Trans i18nKey="last_name"></Trans>
                            </label>
                            <input
                              className="form-control"
                              onChange={this.handleChange}
                              value={this.state.data.lastName}
                              name="lastName"
                              required
                            ></input>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              <Trans i18nKey="email_address"></Trans>
                            </label>
                            <input
                              className="form-control"
                              onChange={this.handleChange}
                              value={this.state.data.email}
                              name="email"
                              required
                            ></input>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              <Trans i18nKey="phone_number"></Trans>
                            </label>
                            <input
                              className="form-control"
                              onChange={this.handleChange}
                              value={this.state.data.phoneNumber}
                              name="phoneNumber"
                              required
                            ></input>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>
                              <Trans i18nKey="message"></Trans>
                            </label>
                            <textarea
                              className="form-control"
                              onChange={this.handleChange}
                              value={this.state.data.message}
                              name="message"
                              rows="7"
                              required
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <button className="btn orange-btn">
                            <Trans i18nKey="send"></Trans>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Map />
      </React.Fragment>
    );
  }
}
