import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import { ArrowRight } from "react-feather";

class Brands extends Component {
  
  render() {
    const { brands } = this.props;
    return (
      <React.Fragment>
        <section className="brands">
          <div className="container">
            <div className="row mb-4">
              <div className="col-md-8">
                <h2 className="dark-text">
                  <Trans i18nKey="our_brands"></Trans>
                </h2>
                <p className="dark-text subtitle mb-0">
                  <Trans i18nKey="in_qudsi"></Trans>
                </p>
              </div>
              <div className="col-md-4 d-flex justify-content-end align-items-center">
                <div>
                  <Link className="btn black-btn" to="/brands">
                    <Trans i18nKey="view_more"></Trans>
                  </Link>
                </div>
              </div>
            </div>
            <div className="row">
              {brands.map(brand => (
                <div key={brand.id} className="col-md-6 col-12 brand-card-div">
                  <Link to={`/brands/${brand.id}/catalogues`}>
                    <div className="brand-card">
                      <img className="card-img shadow" src={brand.image} alt={brand.name_en} />
                      <div className="go-corner" href="#">
                        <div className="go-arrow">
                          <ArrowRight />
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Brands;
