import React, { Component } from "react";
import { Collapse, Navbar, NavbarToggler, Nav, NavItem } from "reactstrap";
import { Link, NavLink, withRouter } from "react-router-dom";
import { useTranslation, withTranslation, Trans } from "react-i18next";
import { Facebook, Globe, Instagram } from "react-feather";

function NavbarItems(props) {
  const path = props.path;
  let navClass = null;

  if (path === "/contact-us" || path === "/") {
    navClass = "text-white ml-3";
  } else {
    navClass = "dark-text ml-3";
  }
  return (
    <React.Fragment>
      <Nav className="ml-auto" navbar>
        <NavItem>
          <NavLink className={navClass} to="/brands">
            <Trans i18nKey="brands"></Trans>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={navClass} to="/contact-us">
            <Trans i18nKey="contact_us"></Trans>
          </NavLink>
        </NavItem>
      </Nav>
    </React.Fragment>
  );
}

function LanguageSwitch({ path }) {
  const { i18n } = useTranslation();

  let iconTheme = null;
  if (path === "/contact-us" || path === "/") {
    iconTheme = "text-white";
  } else {
    iconTheme = "text-dark";
  }

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <React.Fragment>
      <ul className={`${iconTheme} list-unstyled clickable m-0 p-0`}>
        {i18n.language === "ar" ? (
          <li
            className="d-inline-flex align-items-center"
            onClick={() => changeLanguage("en")}
          >
            <span className="ml-2">English</span> <Globe size={18} />
          </li>
        ) : (
          <li
            className="d-inline-flex align-items-center"
            onClick={() => changeLanguage("ar")}
          >
            <span className="mr-2">عربي</span> <Globe size={18} />
          </li>
        )}
      </ul>
    </React.Fragment>
  );
}

export class Header extends Component {
  state = {
    isOpen: false,
  };
  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  changeLanguage = (lng) => {
    this.I18H.changeLanguage(lng);
  };

  render() {
    const { isOpen } = this.state;
    const path = this.props.location.pathname;
    let navbarTheme = null;
    let logoTheme = null;
    let iconTheme = null;
    let hrTheme = null;
    if (path === "/contact-us" || path === "/") {
      navbarTheme = "navbar-dark";
      logoTheme = "/assets/images/logo-white.png";
      iconTheme = "text-white";
      hrTheme = "light-hr"
    } else {
      navbarTheme = "navbar-light";
      logoTheme = "/assets/images/logo.png";
      iconTheme = "text-dark";
      hrTheme = "dark-hr"
    }

    return (
      <section className="header">
        <div className="container">
          <div className="row">
            <div className="col-md-12 d-flex justify-content-between">
              <LanguageSwitch path={path} />
              <ul className="list-inline list-unstyled nav-icons m-0 p-0">
                <li className={`${iconTheme} list-inline-item clickable`}
                    onClick={() => window.open(
                      "https://www.facebook.com/QudsiFurniture/",
                      "_blank"
                      )
                    }>
                  <Facebook size={20} />
                </li>
                <li className={`${iconTheme} list-inline-item clickable`}              
                    onClick={() => window.open(
                      "https://www.instagram.com/qudsi_office_furniture/",
                      "_blank"
                      )
                    }>
                  <Instagram size={20} />
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <hr className={hrTheme} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Navbar expand="md" className={navbarTheme}>
                <Link to="/" className="navbar-brand">
                  <img height="50" src={logoTheme} alt="Logo" />
                </Link>
                <NavbarToggler onClick={this.toggle} />
                <Collapse isOpen={isOpen} navbar>
                  <NavbarItems path={this.props.location.pathname} />
                </Collapse>
              </Navbar>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Header = withRouter(withTranslation()(Header));