import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Card, CardImg, CardBody, CardText } from "reactstrap";
import { Trans, withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import Api from "../../api";
import { ArrowLeft } from "react-feather";

export class Search extends Component {
  state = {
    catalogues: [],
  };

  async componentDidMount() {
    const query = this.props.match.params.query;
    await Api.catalogs.searchByCatalogue(query).then((response) => {
      this.setState({ catalogues: response });
    });
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Search</title>
        </Helmet>
        <section className="catalogues custom-padding">
          <div className="container">
            <div className="row mb-4">
              <div className="col-md-12 d-flex justify-content-between align-items-center">
                <h3 className="dark-text mb-0"><Trans i18nKey="search_results"></Trans></h3>
                <Link className="btn btn-link" to="/">
                  <ArrowLeft size={18} /> <Trans i18nKey="back_home"></Trans>
                </Link>
              </div>
            </div>
            {this.state.catalogues.length > 0 ? (
              <div className="row">
                {this.state.catalogues.map((catalogue) => {
                  return (
                    <div key={catalogue.id} className="col-md-4 col-12">
                      <Link to={`/catalog/${catalogue.id}`}>
                        <Card className="catalogue-card shadow-sm">
                          <CardImg
                            top
                            src={catalogue.image}
                            alt={catalogue.name_en}
                          />
                          <CardBody>
                            <CardText className="dark-text mb-1">
                              {localStorage.getItem("i18nextLng") === "en"
                                ? catalogue.name_en
                                : catalogue.name_ar}
                            </CardText>
                            <p className="gray-text mb-0">
                              {localStorage.getItem("i18nextLng") === "en"
                                ? catalogue.description_en.substring(0, 100)
                                : catalogue.description_ar.substring(0, 100)}
                              ...
                            </p>
                          </CardBody>
                        </Card>
                      </Link>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="row box-height">
                <div className="col-md-12 d-flex flex-column justify-content-center align-items-center">
                  <img
                    src="/assets/images/no-results.svg"
                    alt="No results"
                    height="80"
                    className="mb-3"
                  />
                  <h5><Trans i18nKey="no_results"></Trans></h5>
                </div>
              </div>
            )}
          </div>
        </section>
      </React.Fragment>
    );
  }
}

Search = withRouter(withTranslation()(Search));
