import * as helpers from './helpers';
import BannersApiEndpoints from './banners.api';
import BrandsApiEndpoints from './brands.api';
import CatalogsApiEndpoints from './catalogs.api';

const Api = {
  ...helpers,
  banners: BannersApiEndpoints,
  brands: BrandsApiEndpoints,
  catalogs: CatalogsApiEndpoints,
};

export default Api;
