import React, { Component } from "react";
import { Facebook, Instagram } from "react-feather";
import { withTranslation, Trans } from "react-i18next";
import { withRouter } from "react-router-dom";
export class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="footer">
          <div className="container">
            <div className="row d-flex align-items-center justify-content-center">
              <div className="col-md-3 col-sm-12">
                <img
                  src="/assets/images/logo-white.png"
                  alt="Qudsi Logo"
                  height="50"
                  className="mb-2"
                />
                <p className="text-white mb-0 small w-75">
                  <Trans i18nKey="leading_supplier"></Trans>
                </p>
              </div>
              <div className="col-md-3 col-sm-12">
                <h6 className="text-white mb-2">
                  <Trans i18nKey="contact_us"></Trans>
                </h6>
                <ul className="list-unstyled p-0 m-0">
                  <li className="text-white small mb-1">
                    <Trans i18nKey="tel"></Trans>:{" "}
                    <span dir="ltr">+96264651701</span>
                  </li>
                  <li className="text-white small">
                    <Trans i18nKey="fax"></Trans>:{" "}
                    <span dir="ltr">+96264652701</span>
                  </li>
                  <li className="text-white small">
                    <Trans i18nKey="mobile"></Trans>:{" "}
                    <span dir="ltr">+962777600701</span>
                  </li>
                </ul>
              </div>
              <div className="col-md-3 col-sm-12">
                <h6 className="text-white mb-2">
                  <Trans i18nKey="find_us_at"></Trans>
                </h6>
                <ul className="list-unstyled p-0 m-0">
                  <li className="text-white small mb-1">
                    <Trans i18nKey="email"></Trans>: info@qudsico.com
                  </li>
                  <li className="text-white small">
                    <Trans i18nKey="address"></Trans>:{" "}
                    <Trans i18nKey="wadi_saqra"></Trans>
                  </li>
                </ul>
              </div>
              <div className="col-md-3 col-sm-12">
                <h6 className="text-white mb-2">
                  <Trans i18nKey="reach_us_through"></Trans>
                </h6>
                <ul className="list-unstyled p-0 m-0">
                  <li className="text-white small mb-1">
                    <Trans i18nKey="po_box"></Trans>: 164{" "}
                    <Trans i18nKey="amman"></Trans> 11953{" "}
                    <Trans i18nKey="jordan"></Trans>
                  </li>
                  <li className="text-white small d-flex flex-row align-items-center">
                    <Trans i18nKey="social_media"></Trans>:
                    <ul className="list-unstyled p-0">
                      <li
                        className="list-inline-item mx-1"
                        onClick={() =>
                          window.open(
                            "https://www.facebook.com/QudsiFurniture/",
                            "_blank"
                          )
                        }
                      >
                        <Facebook size={16} />
                      </li>
                      <li
                        className="list-inline-item mx-1"
                        onClick={() =>
                          window.open(
                            "https://www.instagram.com/qudsi_office_furniture/",
                            "_blank"
                          )
                        }
                      >
                        <Instagram size={16} />
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="copyright">
          <p className="text-white mb-0">Copyright {(new Date().getFullYear())} @Qudsi</p>
        </section>
      </React.Fragment>
    );
  }
}
Footer = withRouter(withTranslation()(Footer));
