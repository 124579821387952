import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { withTranslation, Trans } from "react-i18next";
import { Helmet } from "react-helmet";
import { ArrowRight } from "react-feather";
import Api from "../../api";

export class Brands extends Component {
  state = {
    brands: [],
  };

  async componentDidMount() {
    await Api.brands.getBrands().then((response) => {
      this.setState({ brands: response });
    });
  }

  render() {
    const { brands } = this.state;
    return (
      <React.Fragment>
        <Helmet>
          <title>Brands</title>
        </Helmet>
        <section>
          <div className="container">
            <div className="horizontal-banner">
              <div className="row no-gutters">
                <div className="col-md-8">
                  <img src="/assets/images/banner3.png" alt="Brand" />
                </div>
                <div className="col-md-4 banner-caption">
                  <div className="text-content">
                    <h3 className="text-white">
                      <Trans i18nKey="our_brands">Our Brands</Trans>
                    </h3>
                    <p className="text-white">
                      <Trans i18nKey="best_space">
                        Best space solutions that fits all types of offices
                      </Trans>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="brands">
          <div className="container">
            <div className="row mb-4">
              <div className="col-md-12">
                <h2 className="dark-text">
                  <Trans i18nKey="our_brands"></Trans>
                </h2>
              </div>
            </div>
            <div className="row">
              {brands.map((brand) => (
                <div key={brand.id} className="col-md-6 col-12 brand-card-div">
                  <Link to={`/brands/${brand.id}/catalogues`}>
                    <div className="brand-card">
                      <img
                        className="card-img shadow"
                        src={brand.image}
                        alt={brand.name_en}
                      />
                      <div className="go-corner" href="#">
                        <div className="go-arrow">
                          <ArrowRight />
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

Brands = withRouter(withTranslation()(Brands));
