import { getDataFromResponse, getUnAuthenticatedAxios } from './helpers';

const BrandsApiEndpoints = {
  getBrands: () => getUnAuthenticatedAxios()
    .get('brand')
    .then(getDataFromResponse),

  getBrandsWithCatalogues: (id) => getUnAuthenticatedAxios()
    .get(`/brand/${id}?with[]=catalogs`)
    .then(getDataFromResponse),
};

export default BrandsApiEndpoints;
