import React, { Component } from "react";
import GoogleMapReact from "google-map-react";

const Marker = () => <img src="/assets/images/pin.png" alt="Pin" width="45" />;

class Map extends Component {
  static defaultProps = {
    center: {
      lat: 31.9545231,
      lng: 35.9156169,
    },
    zoom: 16,
  };

  render() {
    return (
      <section className="map">
        <div className="map-wrapper">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyCoffKLc8WuCn6-7lnwU-HvsxLdZyN3oEw",
            }}
            defaultCenter={this.props.center}
            defaultZoom={this.props.zoom}
          >
            <Marker lat={31.9545231} lng={35.9156169} />
          </GoogleMapReact>
        </div>
      </section>
    );
  }
}

export default Map;