import React, { Suspense, Component } from "react";
import { Routes } from "./components/shared/routes";
import LoadingScreen from "react-loading-screen";
import "./App.scss";
class App extends Component {
  state = {
    loading: true
  };

  async componentDidMount() {
    // fake promise
    setTimeout(() => this.setState({ loading: false }), 1500);
  }

  render() {
    function AppPage() {
      return (
        <React.Fragment>
          <Suspense fallback=" ">
            <Routes />
          </Suspense>
        </React.Fragment>
      );
    }
    const { loading } = this.state;
    return (
      <LoadingScreen
        loading={loading}
        bgColor="#f1f1f1"
        spinnerColor="#000"
        logoSrc="/assets/images/logo.png"
      >
        <AppPage />
      </LoadingScreen>
    );
  }
}
export default App;
