import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Banners from "./Banners.js";
import Search from "./Search.js";
import Brands from "./Brands.js";
import Api from "../../api";
import Map from "../../components/shared/GoogleMap";

class Home extends Component {
  state = {
    banners: [],
    brands: [],
  };

  async componentDidMount() {
    await Api.banners.getBanners().then((response) => {
      this.setState({ banners: response });
    });
    await Api.brands.getBrands().then((response) => {
      this.setState({ brands: response });
    });
  }

  render() {
    const { banners, brands } = this.state;
    return (
      <React.Fragment>
        <Banners banners={banners} />
        <Search />
        <Brands brands={brands} />
  
      </React.Fragment>
    );
  }
}

export default withTranslation()(withRouter(Home));
