import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { Brands } from "../../views/Brands";
import { CataloguesList } from "../../views/Catalogues";
import { CatalogueDetails } from "../../views/CatalogueDetails";
import { ContactUs } from "../../views/ContactUs";
import { Search } from "../../views/Search";
import Home from "../../views/Home";
import ScrollToTop from "./ScrollToTop";
export class Routes extends Component {
  render() {
    return (
      <BrowserRouter>
        {this.props.i18n.language === "ar" ? (
          <Helmet>
            <link
              rel="stylesheet"
              type="text/css"
              href="/assets/css/rtl-styles.css"
            />
          </Helmet>
        ) : null}
        <Header />
        <ScrollToTop>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/brands" exact component={Brands} />
            <Route
              path="/brands/:id/catalogues"
              exact
              component={CataloguesList}
            />
            <Route
              path="/catalog/:id"
              exact
              component={CatalogueDetails} 
            />
            <Route path="/contact-us" exact component={ContactUs} />
            <Route path="/search/:query" exact component={Search} />
          </Switch>
          <Footer />
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}

Routes = withTranslation()(Routes);
