import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Card, CardImg, CardBody, CardText } from "reactstrap";
import { withTranslation, Trans } from "react-i18next";
import { Helmet } from "react-helmet";
import Api from "../../api";

export class CataloguesList extends Component {
  state = {
    data: {},
    catalogues: [],
  };

  async componentDidMount() {
    const id = this.props.match.params.id;
    await Api.brands.getBrandsWithCatalogues(id).then((response) => {
      this.setState({
        data: response.data,
        catalogues: response.data.catalogs,
      });
    });
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Catalogues List</title>
        </Helmet>
        <section>
          <div className="container">
            <div className="horizontal-banner">
              <div className="row no-gutters">
                <div className="col-md-8">
                  <img src={this.state.data.image} alt="Brand" />
                </div>
                <div className="col-md-4 banner-caption">
                  <div className="text-content">
                    <h3 className="text-white">
                      {localStorage.getItem("i18nextLng") === "en"
                        ? this.state.data.name_en
                        : this.state.data.name_ar}
                    </h3>
                    <p className="text-white">
                      {localStorage.getItem("i18nextLng") === "en"
                        ? this.state.data.description_en
                        : this.state.data.description_ar}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="catalogues">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h3 className="dark-text mb-4">
                  <Trans i18nKey="all_catalogues"></Trans>
                </h3>
              </div>
            </div>
            <div className="row">
              {this.state.catalogues.map((catalogue) => {
                return (
                  <div key={catalogue.id} className="col-md-4 col-12">
                    <Link to={`/catalog/${catalogue.id}`}>
                      <Card className="catalogue-card shadow-sm">
                        <CardImg
                          top
                          src={catalogue.image}
                          alt={catalogue.name_en}
                        />
                        <CardBody>
                          <CardText className="dark-text mb-1">
                            {localStorage.getItem("i18nextLng") === "en"
                              ? catalogue.name_en
                              : catalogue.name_ar}
                          </CardText>
                          <p className="gray-text mb-0">
                          {localStorage.getItem("i18nextLng") === "en"
                              ? catalogue.description_en && catalogue.description_en.substring(0, 100)
                              : catalogue.description_ar && catalogue.description_ar.substring(0, 100)}
                          </p>
                        </CardBody>
                      </Card>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
CataloguesList = withRouter(withTranslation()(CataloguesList));
