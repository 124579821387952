import React, { Component } from "react";
import { Fade } from "react-slideshow-image";

const fadeProperties = {
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  indicators: false,
  arrows: true
};

class Banners extends Component {
  state = {
    activeIndex: 0,
    setActiveIndex: 0
  };

  render() {
    const { banners } = this.props;
    return (
      <React.Fragment>
        <section className="slider" dir="ltr">
          <Fade {...fadeProperties}>
            {banners.map(banner => (
              <div key={banner.id} className="each-fade">
                <div className="image-container">
                  <img src={banner.image} alt="Banner" />
                  <div className="overlay"></div>
                </div>
                <div className="image-caption">
                  <h2 className="text-white mt-0">
                    {localStorage.getItem("i18nextLng") === "en"
                      ? banner.title_en
                      : banner.title_ar}
                  </h2>
                  <p className="text-white mb-0">
                    {localStorage.getItem("i18nextLng") === "en"
                      ? banner.description_en
                      : banner.description_ar}
                  </p>
                </div>
              </div>
            ))}
          </Fade>
        </section>
      </React.Fragment>
    );
  }
}

export default Banners;
