import React, { Component } from "react";
import { withTranslation, Trans } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Api from "../../api";

export class CatalogueDetails extends Component {
  state = {
    details: {},
    path: "",
  };
  async componentDidMount() {
    let id = this.props.match.params.id;
    await Api.catalogs
      .getCatalogueDetails(id)
      .then((response) => {
        const path = response.data.path;
        this.setState({ details: response.data, path });
      })
  }
  render() {
    const { details, path } = this.state;
    return (
      <React.Fragment>
        <Helmet>
          <title>Catalogue Details</title>
        </Helmet>
        <section className="catalogue-details">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <img src={details.image} alt="Catalogue" />
              </div>
              <div className="col-md-8">
                <h3 className="dark-text">
                  {localStorage.getItem("i18nextLng") === "en"
                    ? details.name_en
                    : details.name_ar}
                </h3>
                <p className="gray-text">
                  {localStorage.getItem("i18nextLng") === "en"
                    ? details.description_en
                    : details.description_ar}
                </p>
                <Link
                  className="btn orange-btn"
                  target="_blank"
                  to={`${path}`}
                >
                  <Trans i18nKey="download_catalogue">Download Catalogue</Trans>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
CatalogueDetails = withRouter(withTranslation()(CatalogueDetails));
