import React, { Component } from "react";
import { withTranslation, Trans } from "react-i18next";
import { withRouter } from "react-router-dom";

export default class Search extends Component {
  state = {
    query: ''
  }

  render() {
    const { history, t } = this.props;
    return (
      <React.Fragment>
        <section className="search d-flex align-items-center">
          <div className="container">
            <form className="row">
              <div className="col-md-8">
                <input className="form-control" placeholder={t('search_catalogue')} onChange={(e) => this.setState({ query: e.target.value })} />
              </div>
              <div className="col-md-4">
                <button disabled={!this.state.query} onClick={() => history.push(`/search/${this.state.query}`) } className="btn orange-btn w-100">
                  <Trans i18nKey="search"></Trans>
                </button>
              </div>
            </form>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

Search = withRouter(withTranslation()(Search));
